////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(255, 255, 255);
}
.bg-secondaryColor {
  background-color: rgb(77, 89, 96);
}
.bg-thirdColor {
  background-color: rgb(77, 89, 96);
}
.bg-fourthColor {
  background-color: rgb(174, 161, 101);
}
.bg-fifthColor {
  background-color: rgb(174, 161, 101);
}
.bg-sixthColor {
  background-color: rgb(80, 82, 85);
}
.bg-seventhColor {
  background-color: rgb(80, 82, 85);
}
